.App {
  text-align: center;
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
}
.Main-logo {
  height: 6vmin;
  pointer-events: none;
  width: 110px;
}
.nav-link {
  display: block;
  padding: .5rem 1rem;
  text-align: center;
}
h1 {

  text-shadow: 4px 4px 4px rgba(0,0,0, 0.4);
}

.foot-logo {
  display: block;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header-frame {
  z-index: 99;
  height: 100%;
}
.header-but{
  z-index: 100 !important;
  position: absolute;
}

.App-header {
  background-color: #282c34;
  width: 100% !important;
  height: 82vmin !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url(./AdobeStock.png);
  background-repeat: no-repeat;
  background-size: 100% ;
}
.ser-head{
  background-image: url(./AdobeStock_2.png) !important;
}
.work-head{
  background-image: url(./AdobeStock_3.png) !important;
}
.con-head{
  background-image: url(./AdobeStock_4.png) !important;
}
.blue-head{
  background-image: url(./Bluecoat-main.jpg) !important;
  max-height: 27vmin !important;
}
.kia-head{
  background-image: url(./Kia-main.jpg) !important;
  max-height: 27vmin !important;
}
.fit-head{
  background-image: url(./Fit-main.jpg) !important;
  max-height: 27vmin !important;
}
.hen-head{
  background-image: url(./Henny-main.jpg) !important;
  max-height: 27vmin !important;
}
.brit-head{
  background-image: url(./BA-main.jpg) !important;
  max-height: 27vmin !important;
}
.argos-head{
  background-image: url(./argos-main.jpg) !important;
  max-height: 27vmin !important;
}
.lloyd-head{
  background-image: url(./Lloyds-main.jpg) !important;
  max-height: 27vmin !important;
}
.tif-head{
  background-image: url(./Tiff-main.jpg) !important;
  max-height: 27vmin !important;
}
.hd-head{
  background-image: url(./HD-main.jpg) !important;
  max-height: 27vmin !important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.port {
  text-align: left !important;
  padding-top: 0px !important;
  
  }
.port h4{
text-align: left !important;
padding-top: 0px !important;
padding-bottom: 0px !important;
color: rgb(218, 184, 0);
}
.port p{
  text-align: left !important;
  }
  .port-pic{
    margin-bottom: 50px;
    }
.footer{
  text-align: left;
  color: #ffffff;
  padding-top: 50px;
}
.icon {
  width: 40px!important;
  height: 40px!important;
}
.skill  {
  max-width: 125px;
}
.skill2  {
  max-width: 135px;
}
.skill3  {
  max-width: 272px;
  margin-bottom: 30px;
}

a {
  color: #999 !important;
}
a:hover {
  color: rgb(218, 184, 0) !important;
}
.btn:hover {
  color: #212529 !important;
  text-decoration: none;
}

h2  {
  padding: 30px 0px;
  font-size: 32px !important;
}
h4  {
  padding: 30px 0px;
  font-size: 20px !important;
  line-height: 32px !important;
}
.top {
  margin: 15px;
}
.mr-auto, .mx-auto {
  margin: 0px 0px 0px 50% !important;
  
}
img {
  width: 100%;
}

.test-box  {
  text-align: center;
    height: 180px;
    line-height: 160px;
    overflow: hidden;
    margin: 0px 10% 0px;
}

.test-box h4 {
 
    padding-top: 0px !important;
}
.box-item a {
	float:left;
	width:100%;
	height:100%;
	position:relative;
	overflow:hidden;
}
.box-item a img {
	position:relative;
	z-index:1;
	-webkit-transition: all 4000ms cubic-bezier(.19,1,.22,1) 0ms;
  	-moz-transition: all 4000ms cubic-bezier(.19,1,.22,1) 0ms;
  	transition: all 4000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.box-item a .overlay {
	opacity:0;
	z-index:2;
	-webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}
.box-item a:hover .overlay {
	opacity:0.3;
}
.box-item a:hover img {
	-webkit-transform: scale(1.05);
	-moz-transform:scale(1.05);
	transform: scale(1.05);
}

.box-img  {
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
  z-index: 2;
}

.gallery-items {
	float:left;
	width:100%;
	padding-bottom:50px;
}
.grid-item-holder {
	 float:left;
	 width:100%;
	 height:auto;
	 position:relative;
}

.grid-item {
	position:absolute;
	width:100%;
	z-index:3;
	top:40%;
	left:0;
}
 .grid-item-holder:hover .overlay {
	 opacity:0.7!important;
}
.grid-item h3 {
	font-size:20px;
	text-transform:uppercase;
	font-weight: 700;
	position:relative;
	float:left;
	width:100%;
  color:#fff !important;
  margin-bottom: 0px;
}
.grid-item h3 a {
  color:#fff !important;
  text-decoration: none !important;
}
.grid-item span {
	width:100%;
	float:left;
	font-size:18px;
	opacity:0.6;
	margin-top:0px;
	color:#fff;
}
.grid-item:before , .grid-item:after {
	content:'';
	position:absolute;
	left:50%;
	background:rgba(255,255,255,0.51);
	width:1px;
	height:0;
}
.grid-item:before , .grid-item:after {
	bottom:-50px;
}
.grid-item:after {
	top:-50px;
}
.grid-item h3 , .grid-item span {
	opacity:0;
}
.grid-item-holder:hover .grid-item h3 , .grid-item-holder:hover .grid-item span , .port-desc-holder:hover  .grid-item span  , .port-desc-holder:hover .grid-item h3 , .horizontal_item:hover .grid-item span ,  .horizontal_item:hover .grid-item h3{
	opacity:1;
}
.grid-item-holder:hover   .grid-item:before ,   .grid-item-holder:hover   .grid-item:after  , .port-desc-holder:hover .grid-item:before , .port-desc-holder:hover .grid-item:after , .horizontal_item:hover .grid-item:after , .horizontal_item:hover .grid-item:before{
	 height:40px;
} 



.example_f {
  border-radius: 4px;
  background: linear-gradient(to right, #ffffff, #bdf8fd) !important;
  border: none !important;
  color: #333 !important;
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
}
.example_f span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
  top: -18px;
}
.example_f span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.example_f:hover span {
  padding-right: 25px;
}
.example_f:hover span:after {
  opacity: 1;
  right: 0;
}
.folio {
  padding-right: 0px !important;
    padding-left: 0px !important;
}
.plan {
  background: #594E4D;
  padding: 0px 0px 38px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  margin-bottom: 30px;
  min-height: 540px;
}
.plan-top {
  background: #0D0D0D;
  padding: 20px 0px 5px;
  position: relative;
  color: #fff;
}
.plan-text {
  
  padding: 20px 50px 5px;
  position: relative;
  color: #fff;
}
.news { 
  max-width: 70% !important;
  margin:auto;

}
.newsbox{
  height: 180px;
}

.news input { 
  
  height:auto;
}
.contact-sty { 
  max-width: 70% !important;
  margin:auto;
}

.contact-sty input { 
  width: 100% ;
  height:auto;
  background-color: #fff;
    color: #333;
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: initial;
}
.contact-sty textarea { 
  width: 100% ;
  height:auto;
  background-color: #fff;
    color: #333;
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: initial;
}
.contact-sty select { 
  width: 100% ;
  height:auto;
  background-color: #fff;
    color: #333;
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: initial;
}

.but {
  background-color: #000!important;
    border-radius: 4px;
    box-shadow: none;
    color: #fff!important;
    cursor: pointer;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px!important;
    font-weight: 700;
    line-height: 20px;
    margin: 0!important;
    padding: 10px!important;
    width: 60% !important;
    height: auto;
    border: 0px !important;
    margin-bottom: 20px !important;
}
.price-but{
  left: 32%;
  margin: 0px;
  position: absolute;
  bottom: 30px;
}

@media (min-width: 1024px){

  .App-header {
    background-color: #282c34;
    width: 100% !important;
    max-height:60vmin;
  }

 
}
@media (min-width: 768px){

  .App-header {
    background-color: #282c34;
    width: 100% !important;
    height:45vmin !important;
  }
 
}
@media (max-width: 988px){
.mr-auto, .mx-auto {
  margin: 0px 0% 0px !important;
  
}
.newsbox{
  height: 241px;
}
}
@media (max-width: 767px){
  .folio {
    padding-right: 15px !important;
      padding-left: 15px !important;
  }
  .footer {
    text-align: left;
    color: #ffffff;
    padding-left: 15px !important;
  }
}
@media (min-width: 425px){
  .App-header {
    background-color: #282c34;
    width: 100% !important;
    height:42vmin !important;
  }

  }

  @media (min-width: 320px){
    .App-header {
      background-color: #282c34;
      width: 100% !important;
      height:63vmin !important;
    }
   
 }
 @media (max-width: 425px){
  
  h2 {
    padding: 15px 0px;
    font-size: 21px !important;
}  
.news { 
  max-width: 100% !important;
  margin:auto;
}
h1 {
  font-size: 1.5rem !important;
}
.foot-logo {
  display: none;

}
}

  @media (max-width: 320px){
    h2 {
      padding: 15px 0px;
      font-size: 18px !important;
  }
 }